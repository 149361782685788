
import { Vue, Component } from 'vue-property-decorator';
import DownloadSources from '@/ui/components/components/Downloads.vue';
import { envSettingsDownloadList, envAppShowProjectTypeSelection } from '@/utils/env';

/**
 * Component that shows downloads settings tab
 */
@Component({
  components: {
    DownloadSources,
  },
})
export default class Downloads extends Vue {
  showAdditionalDownloads = envAppShowProjectTypeSelection;
  settingsDownloadList = envSettingsDownloadList;
  chips = [
    {
      title: 'Download Certificate',
      locale: 'uiComponents.settings.downloads.chips.certificate',
      value: 'Certificate',
      source: './files/mqtt_lynus_io.crt',
      isLoaded: false,
      group: 'main',
    },
    {
      title: 'lynusStandarts',
      locale: 'uiComponents.settings.downloads.chips.lynusStandarts',
      value: 'lynusStandarts',
      source: './files/Lynus_Standards.compiled-library',
      isLoaded: false,
      group: 'lynusCommunicator',
    },
    {
      title: 'lynusCommunicator2',
      locale: 'uiComponents.settings.downloads.chips.lynusCommunicator2',
      value: 'lynusCommunicator2',
      source: './files/Lynus_Communicator_V2_0.compiled-library',
      isLoaded: false,
      group: 'lynusCommunicator',
    },
    {
      title: 'lynusPersistentData1',
      locale: 'uiComponents.settings.downloads.chips.lynusPersistentData1',
      value: 'lynusPersistentData1',
      source: './files/Lynus_Persistent_Data_V1_0.compiled-library',
      isLoaded: false,
      group: 'lynusCommunicator',
    },
  ]

  currentGroup = 'main'

  get filteredByConfig() {
    return this.chips.filter((e: any) => envSettingsDownloadList.includes(e.value));
  }
  get filteredChips() {
    return this.filteredByConfig.filter((el: any) => el.group === this.currentGroup);
  }
  get filteredChipsCommunicator() {
    return this.filteredByConfig.filter((el: any) => el.group === 'lynusCommunicator');
  }
}
